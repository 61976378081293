export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36')
];

export const server_loads = [];

export const dictionary = {
		"/": [2],
		"/developer": [3],
		"/introduction": [4],
		"/login": [5],
		"/login/forgot-password": [6],
		"/main/benefits/bav": [7],
		"/main/benefits/betreuungskosten": [8],
		"/main/benefits/bkv": [9],
		"/main/benefits/custom": [10],
		"/main/benefits/erholungsbeihilfe": [11],
		"/main/benefits/essenszuschuss": [12],
		"/main/benefits/fahrtkosten": [13],
		"/main/benefits/internetpauschale": [14],
		"/main/benefits/jobbike": [15],
		"/main/benefits/mpp": [16],
		"/main/benefits/oepnv_tickets": [17],
		"/main/benefits/planner": [18],
		"/main/benefits/sachbezug": [19],
		"/main/benefits/telefonkosten": [20],
		"/main/chat": [21],
		"/main/chat/conversation": [22],
		"/main/dashboard": [23],
		"/main/first-steps": [24],
		"/menu": [25],
		"/menu/documents": [26],
		"/menu/legal": [27],
		"/menu/legal/privacy-agreement": [28],
		"/menu/profile": [29],
		"/menu/profile/address": [30],
		"/menu/profile/contact-data": [31],
		"/menu/profile/personal-data": [32],
		"/menu/profile/position-data": [33],
		"/menu/profile/tax-data": [34],
		"/menu/settings": [35],
		"/onboarding": [36]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';